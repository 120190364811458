import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import sanitizeHtml from 'sanitize-html';
import { Container, Grid, Box, TextField, Typography, Radio, RadioGroup } from '@mui/material';
import Layout from '../components/LayoutComponent';
// import Radio from '@material-ui/core/Radio';
import androidIcon from './../images/img-android_logo.png';
import appleIcon from './../images/img-apple_logo.png';
import './../styles/howdoi.css';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'gatsby';
import Seo from '../components/SeoComponent';
//import AnswerExpanded from '../components/AnswerExpanded';

const HowDoI = (props) => {
    const howtoData = get(props, 'data.allQuestions.nodes');
    //const metas = get(props, 'data.allMetaTags.nodes');

    const metaTitle = 'How To | Asurion Mobile+';
    const metaDescription =
        'Asurion Mobile+ covers your phone in the event of accidental damage, loss or theft. Insure your phone based on the level of coverage you want.';

    const [search, setSearch] = useState('');
    const [selectedOs, setSelectedOS] = useState('');
    const [filteredQues, setFilteredQues] = useState([]);
    const [topTen, setTopTen] = useState(howtoData.top10);
    //const [readMore, setReadMore] = useState();
    const [selectedAccTitles, setSelectedAccTitles] = useState([]);

    const renderingOptions = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <>{children}</>,
        },
    };

    const location = props && props.location ? sanitizeHtml(props.location) : '';

    const [ANDROID, IOS] = ['ANDROID', 'IOS'];
    const OSS = [
        {
            name: ANDROID,
            img: androidIcon,
        },
        {
            name: IOS,
            img: appleIcon,
        },
    ];

    const handleOSChange = (event) => {
        setSearch('');
        //setReadMore();
        setSelectedAccTitles([]);
        setSelectedOS(event.target.value === selectedOs ? '' : event.target.value);
    };

    const handleInputChange = (event) => {
        const { value } = event.target;
        let defaultFilteredQuestions = null;
        setSearch(value);
        if (!value) {
            defaultFilteredQuestions = getDefaultFilteredQuestions();
            setFilteredQues(defaultFilteredQuestions);
        } else {
            defaultFilteredQuestions = getDefaultFilteredQuestions(true);
            const data = defaultFilteredQuestions?.filter((ques) =>
                ques.question.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredQues(data);
        }
    };

    const getDefaultFilteredQuestions = (isSearch = false) => {
        const questionsFilterOS = howtoData.filter((q) => {
            if (selectedOs) {
                return q.checkOs.includes(selectedOs);
            } else {
                return true;
            }
        });

        const questionsFilterTopTen = questionsFilterOS.filter((q) => {
            if (topTen) {
                return q.top10;
            } else {
                return true;
            }
        });

        if (isSearch) return questionsFilterOS;

        return questionsFilterTopTen;
    };

    const handleViewAll = () => {
        setTopTen(!topTen);
    };

    function getFilteredQuestions() {
        return howtoData.filter(
            (question) =>
                question.question.toLowerCase().includes(search) &&
                (!topTen || question.top10) &&
                (!selectedOs || question.checkOs.includes(selectedOs))
        );
    }

    /*const toggleReadMore = (question) => {
        setReadMore(question);
    };*/

    const HowToAccordion = ({ title, questionUrl, osChosen, children, id, answer }) => {
        const togglePanel = (accItemTitle) => {
            if (selectedAccTitles.includes(accItemTitle)) {
                setSelectedAccTitles(selectedAccTitles.filter((item) => item !== accItemTitle));
                document.title = `${accItemTitle} | Asurion Mobile+`;
            } else {
                setSelectedAccTitles([...selectedAccTitles, accItemTitle]);
                document.title = `${accItemTitle} | Asurion Mobile+`;
            }
        };
        return (
            <Box component="div" id={id} style={{ position: 'relative', boxShadow: '0 0 #0000', border: '#d5d6da' }}>
                <Box
                    component="div"
                    onClick={(e) => togglePanel(`${title}`)}
                    style={{
                        cursor: 'pointer',
                        padding: '20px',
                        color: '#000',
                        backgroundColor: '#fff',
                        display: 'flex',
                        fontSize: '16px',
                        minHeight: '0px',
                        fontWeight: '600',
                        alignItems: 'flex-start',
                    }}
                >
                    <Box
                        component="div"
                        className="flex items-center flex-grow leading-19"
                        style={{
                            transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            display: 'flex',
                            alignItems: 'center',
                            flexGrow: '1',
                            lineHeight: '19px',
                        }}
                    >
                        {title}
                    </Box>
                    {selectedAccTitles.includes(`${title}`) ? (
                        <KeyboardArrowUpIcon id="btn-howto-question-ans-up" label={title} />
                    ) : (
                        <KeyboardArrowDownIcon id="btn-howto-question-ans-down" label={title} />
                    )}
                </Box>
                {selectedAccTitles.includes(`${title}`) ? (
                    <Box component="div" className="accordianMargin accordian-child-margin">
                        {children}
                    </Box>
                ) : null}
            </Box>
        );
    };

    useEffect(() => {
        setFilteredQues(getFilteredQuestions());
    }, [topTen, selectedOs]);

    // const domainUrl = process.env.GATSBY_DOMAIN_URL || '';
    const domainUrl = '';
    const canonicalUrl =
        props && props.pageContext && props.pageContext.canonicalPath
            ? domainUrl + props.pageContext.canonicalPath
            : domainUrl + props.path;

    return (
        <>
            <Seo title={metaTitle} description={metaDescription} image="" canonical={canonicalUrl} />
            <Layout
                location={location}
                subcomponent={
                    <>
                        <Container maxWidth="lg" sx={{ p: { xs: 0, md: '0 1rem' } }}>
                            <Container ssm sx={{ p: { xs: 0, md: '1rem' } }}>
                                <Grid
                                    xs={12}
                                    md={8}
                                    sx={{ mr: 'auto', ml: 'auto', maxWidth: { xs: 'unset', md: '768px' } }}
                                >
                                    <Box
                                        component="section"
                                        sx={{
                                            paddingTop: 0,
                                            paddingBottom: '16px',
                                            backgroundColor: '#F0F3FF',
                                        }}
                                    >
                                        <Box
                                            component="div"
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                position: 'relative',
                                                backgroundColor: '#8223D2',
                                                height: 'auto',
                                                padding: '20px',
                                                overflowY: 'hidden',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '50px',
                                                    textAlign: 'center',
                                                    color: '#fff',
                                                    display: 'flex',
                                                    lineHeight: '60px',
                                                }}
                                            >
                                                HOW DO I
                                                <Box
                                                    component="span"
                                                    style={{
                                                        color: 'white',
                                                        position: 'relative',
                                                        fontWeight: '900',
                                                        marginLeft: '20px',
                                                        fontSize: '150px',
                                                        fontFamily: 'Arial',
                                                        marginTop: '-0.25rem',
                                                    }}
                                                >
                                                    ?
                                                </Box>
                                            </Typography>
                                        </Box>

                                        <Box
                                            component="div"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginTop: '11px',
                                                position: 'relative',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    padding: '20px',
                                                    fontSize: '16px',
                                                    lineHeight: '19px',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Ask your question
                                            </Typography>
                                            <Box
                                                component="div"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    right: '10px',
                                                }}
                                            >
                                                {OSS.map((os) => (
                                                    <Box
                                                        component="div"
                                                        key={os.name}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            marginLeft: '12px',
                                                        }}
                                                    >
                                                        <img src={os.img} htmlFor={os.name} alt={os.name} />
                                                        {/* causes nav issue */}
                                                        <Radio
                                                            size="small"
                                                            checked={selectedOs && selectedOs === os.name}
                                                            value={os.name}
                                                            name="os"
                                                            id={os.name}
                                                            onClick={handleOSChange}
                                                            color="primary"
                                                        />
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Box>
                                        <Box
                                            component="div"
                                            className="ip-box-shadow-effect"
                                            style={{ paddingRight: '16px', paddingLeft: '16px' }}
                                        >
                                            <Box
                                                sx={{
                                                    maxWidth: '100%',
                                                }}
                                                style={{ position: 'relative' }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Type your query"
                                                    id="fullWidth"
                                                    style={{ color: 'black', backgroundColor: 'white' }}
                                                    onChange={handleInputChange}
                                                    value={search}
                                                />
                                                <SearchIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        transform: 'translateY(15px)',
                                                    }}
                                                    htmlColor="#8223D2"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <section>
                                        <Box
                                            component="div"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Typography
                                                className="p-5 font-semibold text-xl leading-6"
                                                style={{
                                                    padding: '20px',
                                                    fontWeight: '600',
                                                    fontSize: '1.25rem',
                                                    lineHeight: '1.5rem',
                                                }}
                                            >
                                                {topTen ? 'Top 10' : 'All'} Questions
                                            </Typography>
                                            <Box
                                                component="span"
                                                style={{
                                                    fontSize: '16px',
                                                    lineHeight: '19px',
                                                    padding: '20px',
                                                    textDecoration: 'underline',
                                                    color: '#8223D2',
                                                    cursor: 'pointer',
                                                }}
                                                id={topTen ? 'btn-howto-view-all' : 'btn-howto-top-ten'}
                                                onClick={handleViewAll}
                                            >
                                                {topTen ? 'View All' : 'Top Ten'}
                                            </Box>
                                        </Box>
                                    </section>
                                    <section>
                                        <Box component="div" sx={{ background: 'white' }}>
                                            {filteredQues.map((q) => (
                                                <HowToAccordion
                                                    osChosen={q.checkOs.join('-')}
                                                    questionUrl={q.questionUrl}
                                                    title={q.question}
                                                    key={q.question}
                                                    id="btn-howto-question-ans"
                                                    children={
                                                        <Box
                                                            component="div"
                                                            className="how-do-i accordian-child-margin"
                                                            style={{ lineHeight: '18px' }}
                                                        >
                                                            {renderRichText(q.answer, renderingOptions)}

                                                            {q.answer && (
                                                                <Link
                                                                    underline="always"
                                                                    style={{
                                                                        fontSize: '14px',
                                                                        lineHeight: '21px',
                                                                        marginLeft: '16px',
                                                                        color: '#8223D2',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    to={`${domainUrl}/articles/how-to/${q.questionUrl
                                                                        .replace(/\s/g, '-')
                                                                        .replace(/:/g, '')}/`}
                                                                >
                                                                    <br />
                                                                    Read More {'>'}
                                                                </Link>
                                                            )}
                                                        </Box>
                                                    }
                                                />
                                            ))}
                                        </Box>
                                    </section>
                                </Grid>
                            </Container>
                        </Container>
                    </>
                }
            />
        </>
    );
};
export default HowDoI;

export const howdoiQuery = graphql`
    query howdoiQuery {
        allQuestions: allContentfulHowDoIPage(filter: { node_locale: { eq: "en-US" } }) {
            nodes {
                createdAt
                checkOs
                top10
                question
                answer {
                    raw
                }
                questionUrl
                detailsHeader1
                detailsDescription1 {
                    raw
                }
                detailsHeader2
                detailsDescription2 {
                    raw
                }
                section1Image {
                    file {
                        url
                    }
                }
                section2Image {
                    file {
                        url
                    }
                }
            }
        }
        allMetaTags: allContentfulHowDoIPage(filter: { node_locale: { eq: "en-US" } }) {
            nodes {
                metaTags {
                    title
                    description
                    robots
                }
            }
        }
    }
`;
